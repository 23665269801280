<template>
  <div class="user-center">
    <div class="user-center-main" v-if="user && user.userName">
      <div style="display: flex; justify-content: center; align-items: center; font-size: 18px; margin-top: 20px; color: #b3adad; margin-bottom: 20px;">
        <svg t="1664438021262" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8808" width="40" height="40"><path d="M512 74.666667C270.933333 74.666667 74.666667 270.933333 74.666667 512S270.933333 949.333333 512 949.333333 949.333333 753.066667 949.333333 512 753.066667 74.666667 512 74.666667z m0 160c70.4 0 128 57.6 128 128s-57.6 128-128 128-128-57.6-128-128 57.6-128 128-128z m236.8 507.733333c-23.466667 32-117.333333 100.266667-236.8 100.266667s-213.333333-68.266667-236.8-100.266667c-8.533333-10.666667-10.666667-21.333333-8.533333-32 29.866667-110.933333 130.133333-187.733333 245.333333-187.733333s215.466667 76.8 245.333333 187.733333c2.133333 10.666667 0 21.333333-8.533333 32z" p-id="8809" fill="#13227a"></path></svg>用户信息修改
      </div>
      <a-spin :spinning="loading">
        <a-tabs>
          <a-tab-pane key="1">
            <span slot="tab">
              <a-icon type="user"></a-icon>
              用户基本信息修改
            </span>
            <div class="form-item">
              <div class="form-title">
                昵称
              </div>
              <div class="form-content">
                <a-input placeholder="昵称" size="large" style="width: 300px;" v-model="user.nickName" v-on:keyup.enter="updateUser">
                  <a-icon slot="prefix" type="user"></a-icon>
                </a-input>
              </div>
            </div>

            <div class="form-item">
              <div class="form-title">
                邮箱
              </div>
              <div class="form-content">
                <a-input placeholder="邮箱" size="large"  style="width: 300px;" v-model="user.email" disabled>
                  <a-icon slot="prefix" type="mail"></a-icon>
                </a-input>
              </div>
            </div>
            <div class="form-item">
              <div class="form-title">
                手机号
              </div>
              <div>
                <a-input placeholder="手机号" style="width: 300px;" v-model="user.phone" v-on:keyup.enter="updateUser" disabled>
                  <a-icon slot="prefix" type="phone"></a-icon>
                </a-input>
<!--                <a-button type="link">修改</a-button>-->
              </div>
            </div>
            <div class="form-item">
              <div class="form-title">
              </div>
              <a-button type="primary" icon="save" size="large" @click="updateUser">保存</a-button>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2">
            <span slot="tab">
              <a-icon type="lock"></a-icon>
              用户密码修改
            </span>
            <div class="form-item">
              <div class="form-title">
                旧密码
              </div>
              <div>
                <a-input-password placeholder="旧密码"
                                  size="large"
                                  type="password"
                                  style="width: 300px;"
                                  v-model="userPwd.oldPassword"
                                  v-on:keyup.enter="updatePwd">
                  <a-icon slot="prefix" type="lock"></a-icon>
                </a-input-password>
              </div>
            </div>
            <div class="form-item">
              <div class="form-title">
                新密码
              </div>
              <div>
                <a-input-password placeholder="新密码"
                                  size="large"
                                  type="password"
                                  style="width: 300px;"
                                  v-model="userPwd.newPassword"
                                  v-on:keyup.enter="updatePwd">
                  <a-icon slot="prefix" type="lock"></a-icon>
                </a-input-password>
              </div>
            </div>
            <div class="form-item">
              <div class="form-title">
                确认新密码
              </div>
              <div>
                <a-input-password placeholder="确认新密码"
                                  size="large"
                                  type="password"
                                  style="width: 300px;"
                                  v-model="userPwd.checkNewPassword"
                                  v-on:keyup.enter="updatePwd">
                  <a-icon slot="prefix" type="lock"></a-icon>
                </a-input-password>
              </div>
            </div>
            <div class="form-item">
              <div class="form-title">
              </div>
              <a-button type="primary" icon="save" @click="updatePwd" size="large">修改密码</a-button>
            </div>
          </a-tab-pane>
        </a-tabs>
      </a-spin>
    </div>
    <a-empty v-else description="未获取到当前用户数据" style="padding-top: 30px;"></a-empty>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      user: {},
      userPwd: {
        id: '',
        oldPassword: '',
        newPassword: '',
        checkNewPassword: ''
      },
      token: ''
    };
  },

  created() {
    const queryStringParam = this.$url.getQueryString(window.location.href);
    if (queryStringParam && queryStringParam.token) {
      this.token = queryStringParam.token;
      this.getUser();
    }
  },

  methods: {
    getUser() {
      const url = this.$url.getBackendApiUrl('authorization/user/get');
      const headers = {};
      headers.Authorization = this.token;
      this.loading = true;
      this.$http.get(url, { headers: headers }).then((res) => {
        if (res.status === 200 && res.data.success && res.data.data) {
          this.user = res.data.data;
        } else {
          this.user = {};
        }
        this.loading = false;
      }).catch((e) => {
        this.user = {};
        console.error(e);
        this.loading = false;
      });
    },

    updateUser() {
      if (!this.user || !this.user.id) {
        this.$message.error('修改的用户不存在');
        return;
      }

      if (!this.user.nickName) {
        this.$message.error('昵称不能为空');
        return;
      }
      const url = this.$url.getBackendApiUrl('authorization/user/update/user/self');
      const headers = {};
      headers.Authorization = this.token;
      this.loading = true;
      this.$http.post(url, this.user, { headers: headers }).then((res) => {
        if (res.status === 200 && res.data.success && res.data.data) {
          this.$message.success('修改成功');
        } else {
          this.$message.error(res.data.errMessage);
        }
        this.loading = false;
      }).catch((e) => {
        this.$message.error('修改失败');
        this.loading = false;
        console.error(e);
      });
    },

    updatePwd() {
      if (!this.user || !this.user.id) {
        this.$message.error('修改的用户不存在');
        return;
      }

      if (!this.userPwd.oldPassword) {
        this.$message.error('旧密码不能为空');
        return;
      }

      if (!this.userPwd.newPassword) {
        this.$message.error('新密码不能为空');
        return;
      }

      if (!this.userPwd.checkNewPassword) {
        this.$message.error('确认新密码不能为空');
        return;
      }
      this.userPwd.id = this.user.id;
      const url = this.$url.getBackendApiUrl('authorization/user/update/password/self');
      const headers = {};
      headers.Authorization = this.token;
      this.loading = true;
      this.$http.post(url, this.userPwd, { headers: headers }).then((res) => {
        if (res.status === 200 && res.data.success && res.data.data) {
          this.$message.success('修改成功');
        } else {
          this.$message.error(res.data.errMessage);
        }
        this.loading = false;
      }).catch((e) => {
        this.$message.error('修改失败');
        this.loading = false;
        console.error(e);
      });
    }
  }
};
</script>

<style scoped>
.user-center {
  display: flex;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.user-center-main {
  background-color: #fff;
  border-radius: 10px;
  width: 550px;
  min-height: 500px;
  box-shadow: 0 14px 28px #cdc5c5, 0 10px 10px #cdc5c5;
  padding: 20px;
  margin-top: 80px;
}

.form-item {
  margin: 20px 20px;
  display: flex;
  align-items: center;
}

.form-title {
  width: 100px;
}

.form-content {
}
</style>
