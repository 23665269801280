<template>
    <div class="login-content">
      <div class="login" v-if="!isFast">
        <div style="display: flex; justify-content: center; font-size: 18px; margin-top: 40px; color: #b3adad; margin-bottom: 20px;">
          {{ appInfo.name }}登录
        </div>
        <div style="margin: 30px;">
          <a-spin :spinning="loading">
            <a-tabs>
              <a-tab-pane key="1" tab="密码">
                <div style="margin-top: 15px;display:none;">
                  <a-input placeholder="请输入企业域"
                           size="large"
                           v-model="userLogin.tenant"
                           v-on:keyup.enter="loginByUserName">
                    <a-icon slot="prefix" type="home"></a-icon>
                  </a-input>
                </div>
                <div style="margin-top: 15px;">
                  <a-input placeholder="请输入用户名"
                           size="large"
                           v-model="userLogin.userName"
                           v-on:keyup.enter="loginByUserName">
                    <a-icon slot="prefix" type="user"></a-icon>
                  </a-input>
                </div>
                <div style="margin-top: 15px;">
                  <a-input-password placeholder="请输入密码"
                                    size="large"
                                    type="password"
                                    v-model="userLogin.password"
                                    v-on:keyup.enter="loginByUserName">
                    <a-icon slot="prefix" type="lock"></a-icon>
                  </a-input-password>
                </div>
  
                <div style="display: flex; margin-top: 15px;" v-if="isCaptcha">
                  <a-input v-model="userLogin.captcha" placeholder="请输入验证码" size="large" style="width: 200px; margin-right: 20px;" v-on:keyup.enter="loginByUserName">
                    <a-icon slot="prefix" type="picture"></a-icon>
                  </a-input>
                  <div>
                    <img :src="captchaUrl" style="cursor: pointer;" @click="refresh"/>
                  </div>
                </div>
  
                <div style="margin-top: 15px; display: flex; justify-content: right;">
                  <a-checkbox @change="rememberMe" v-model="isRememberMe">记住我</a-checkbox>
                </div>
                <div style="margin-top: 15px;">
                  <a-button style="width: 100%;"
                            type="primary"
                            size="large"
                            @click="loginByUserName">
                    登录
                  </a-button>
                </div>
                <div style="margin-top: 25px; text-align: center;" v-if="errorMsg">
                    <span style="text-align: center; color: #e75353; font-size: 14px;">
                      {{ errorMsg }}
                    </span>
                </div>
              </a-tab-pane>
              <a-tab-pane key="2" tab="短信验证码" v-if="appInfo && appInfo.messageCode">
                <div style="margin-top: 15px;" v-if="isTenant">
                  <a-input placeholder="请输入企业域"
                           size="large"
                           v-model="phoneLogin.tenant" v-on:keyup.enter="loginByPhone">
                    <a-icon slot="prefix" type="home"></a-icon>
                  </a-input>
                </div>
                <div style="margin-top: 15px;">
                  <a-input placeholder="请输入手机号"
                           size="large"
                           v-model="phoneLogin.phone" v-on:keyup.enter="loginByPhone">
                    <a-icon slot="prefix" type="mobile"></a-icon>
                  </a-input>
                </div>
                <div style="margin-top: 15px; display: flex;">
                  <a-input placeholder="请输入验证码"
                           size="large"
                           v-model="phoneLogin.code" v-on:keyup.enter="loginByPhone">
                    <a-icon slot="prefix" type="message"></a-icon>
                  </a-input>
                  <a-button type="link" style="margin-top: 5px; width: 120px;" :disabled="timer > 0 && timer !== 60" @click="send">
                    {{ (timer === 0 || timer === 60) ? '获取验证码' : '重新发送(' + timer + ')' }}
                  </a-button>
                </div>
                <div style="display: flex; margin-top: 15px;" v-if="isCaptcha">
                  <a-input v-model="phoneLogin.captcha" placeholder="请输入验证码" size="large" style="width: 200px; margin-right: 20px;" v-on:keyup.enter="loginByPhone">
                    <a-icon slot="prefix" type="picture"></a-icon>
                  </a-input>
                  <div>
                    <img :src="captchaUrl" style="cursor: pointer;" @click="refresh"/>
                  </div>
                </div>
                <div style="margin-top: 15px; display: flex; justify-content: right;">
                  <a-checkbox @change="rememberMe" v-model="isRememberMe">记住我</a-checkbox>
                </div>
                <div style="margin-top: 15px;">
                  <a-button style="width: 100%;"
                            type="primary"
                            size="large"
                            @click="loginByPhone">
                    登录
                  </a-button>
                </div>
                <div style="margin-top: 25px; text-align: center;" v-if="errorMsg">
                  <span style="text-align: center; color: #e75353; font-size: 14px; ">
                    {{ errorMsg }}
                  </span>
                </div>
              </a-tab-pane>
            </a-tabs>
          </a-spin>
        </div>
      </div>
  
      <div class="login-fast" v-bind:class="{ 'login-ss': isCustom }" v-if="isFast">
        <div>
          <div class="more-login" @click="changeLoginMode">
            <div style="height: 32px; line-height: 32px; margin-right: 15px; margin-left: 10px;">更多登录</div>
            <div>
              <svg t="1668569724281" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11675" width="32" height="32"><path d="M991.744 763.904l-102.4-50.176c-4.608-2.048-9.728-2.048-14.336 0l-338.944 166.4c-14.848 7.168-32.768 7.168-47.616 0l-338.944-166.4c-4.608-2.048-9.728-2.048-14.336 0l-102.4 50.176c-12.8 6.144-12.8 24.576 0 30.72l455.68 223.744c14.848 7.168 32.768 7.168 47.616 0l455.68-223.744c12.8-6.144 12.8-24.064 0-30.72z" p-id="11676" fill="#13227a"></path><path d="M991.744 499.712l-102.4-50.176c-4.608-2.048-9.728-2.048-14.336 0l-338.944 166.4c-14.848 7.168-32.768 7.168-47.616 0L148.992 449.536c-4.608-2.048-9.728-2.048-14.336 0l-102.4 50.176c-12.8 6.144-12.8 24.576 0 30.72l455.68 223.744c14.848 7.168 32.768 7.168 47.616 0l455.68-223.744c13.312-6.144 13.312-24.576 0.512-30.72z" p-id="11677" fill="#13227a"></path><path d="M488.448 483.328l-455.68-223.744c-12.8-6.144-12.8-24.576 0-30.72L488.448 5.12c14.848-7.168 32.768-7.168 47.616 0l455.68 223.744c12.8 6.144 12.8 24.576 0 30.72l-455.68 223.744c-15.36 7.168-32.768 7.168-47.616 0z" p-id="11678" fill="#13227a"></path></svg>
            </div>
          </div>
          <div style="display: flex; justify-content: center; width: 100%; font-size: 18px; margin-top: 20px; color: #b3adad; margin-bottom: 20px;">{{ appInfo.name }}登录</div>
        </div>
        <div style="margin: 20px 30px;">
          <a-spin :spinning="loading">
            <a-tabs>
              <a-tab-pane key="3" tab="快速登录">
                <div>点击下方已经登录的应用快速登录</div>
                <div style="justify-content: center; display: flex; flex-wrap: nowrap; overflow: auto;">
                  <div v-for="(item, index) of appInfo.ticketConfirmResponses" :key="index" class="login-fast-item" @click="loginByTicket(item)">
                    <div>
                      <div>
                        <svg t="1668564709635" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2703" width="64" height="64"><path d="M483.555556 10.723556a85.333333 85.333333 0 0 1 82.574222 0l381.952 211.057777a85.333333 85.333333 0 0 1 44.088889 74.695111v419.015112a85.333333 85.333333 0 0 1-44.088889 74.666666l-381.952 211.114667a85.333333 85.333333 0 0 1-82.545778 0l-381.952-211.057778a85.333333 85.333333 0 0 1-44.088889-74.723555v-418.986667A85.333333 85.333333 0 0 1 101.632 221.866667L483.555556 10.695111v0.028445zM222.151111 338.688a42.666667 42.666667 0 0 0 16.668445 58.026667l243.370666 134.456889v268.174222a42.666667 42.666667 0 0 0 85.333334 0v-268.174222l243.370666-134.542223a42.666667 42.666667 0 0 0-41.244444-74.638222l-244.792889 135.253333-244.736-135.253333a42.666667 42.666667 0 0 0-57.969778 16.725333z" p-id="2704" fill="#316e8f"></path></svg>
                      </div>
                      <div style="margin-top: 20px;">
                        {{ item.appName }}({{ item.tenant }})
                      </div>
                    </div>
                  </div>
                </div>
                <div style="margin-top: 25px; text-align: center;" v-if="errorMsg">
                  <span style="text-align: center; color: #e75353; font-size: 14px; ">
                    {{ errorMsg }}
                  </span>
                </div>
              </a-tab-pane>
            </a-tabs>
          </a-spin>
        </div>
      </div>
  
    </div>
</template>
  
  <script>
  import { mapState } from 'vuex';
  import * as constant from '../public/constant';
  
  export default {
    computed: {
      ...mapState({
        errorMsg: state => state.login.data.errorMsg,
        error: state => state.login.data.error,
        loading: state => state.login.data.loading,
        userLogin: state => state.login.userLogin,
        phoneLogin: state => state.login.phoneLogin,
        appInfo: state => state.login.data.appInfo,
        isTenant: state => state.login.data.isTenant,
        isFast: state => state.login.data.isFast,
        isCaptcha: state => state.login.data.isCaptcha,
        captchaUrl: state => state.login.data.captchaUrl,
        timer: state => state.login.data.timer,
      }),
      isRememberMe: {
        get() {
          return this.$store.state.login.data.isRememberMe;
        },
  
        set(val) {
          this.$store.commit('rememberMe', val);
        }
      }
    },
  
    created() {
      this.$store.dispatch('init');
      this.$store.dispatch('copyRememberMeUser');
      this.$store.dispatch('copyRememberMePhone');
      this.$store.dispatch('getAppInfo').then(() => {
        this.$store.dispatch('triggerCaptcha').then((res) => {
          if (res) {
            this.$store.dispatch('getCaptcha');
          }
        });
      });
      this.userLogin.tenant='AKH'
      
    },
  
    methods: {
      rememberMe(e) {
        this.$store.dispatch('rememberMe', e.target.checked);
      },
  
      send() {
        this.$store.dispatch('send', this).then(() => {
          this.$store.dispatch('triggerCaptcha').then((res) => {
            if (res) {
              this.$store.dispatch('getCaptcha');
            }
          });
        });
      },
  
      loginByUserName() {
        this.$store.dispatch('loginByUserName', this).then((type) => {
          if (type === constant.CAPTCHA) {
            this.$store.dispatch('triggerCaptcha').then((res) => {
              if (res) {
                this.$store.dispatch('getCaptcha');
              }
            });
          }
  
          if (type === constant.MFA) {
            this.$router.push('/mfa');
          }
        });
      },
  
      loginByPhone() {
        this.$store.dispatch('loginByPhone', this).then((type) => {
          if (type === constant.CAPTCHA) {
            this.$store.dispatch('triggerCaptcha').then((res) => {
              if (res) {
                this.$store.dispatch('getCaptcha');
              }
            });
          }
  
          if (type === constant.MFA) {
            this.$router.push('/mfa');
          }
        });
      },
  
      loginByTicket(item) {
        this.$store.dispatch('loginByTicket', item);
      },
  
      changeLoginMode() {
        this.$store.dispatch('changeLoginMode');
      },
  
      refresh() {
        this.$store.dispatch('triggerCaptcha').then((res) => {
          if (res) {
            this.$store.dispatch('getCaptcha');
          }
        });
      },
    }
  };
  </script>
  
  <style lang="less" scoped>
  .login-content {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  
  .login {
    background-color: #fff;
    border-radius: 10px;
    width: 420px;
    min-height: 470px;
    box-shadow: 0 14px 28px #cdc5c5, 0 10px 10px #cdc5c5;
    margin-top: 80px;
  }
  
  .login-fast {
    background-color: #fff;
    border-radius: 10px;
    min-width: 420px;
    height: 470px;
    box-shadow: 0 14px 28px #cdc5c5, 0 10px 10px #cdc5c5;
    margin-top: 100px;
  }
  
  .login-ss {
    box-shadow: 0 0 0;
  }
  
  .more-login {
    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: end;
    cursor: pointer;
  }
  
  .login-fast-item {
    justify-content: center;
    display: flex;
    box-shadow: 0px 14px 28px #9d9d9d;
    margin: 20px 20px;
    padding: 30px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }
  
  </style>
  