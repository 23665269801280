<template>
  <div class="login-content">
    <div class="navContent">
      <div class="exhibitionMap">
        <div class="abs test">
          <p class="test1">非车业务数据</p>
          <p class="test2">智能材料收集系统</p>
          <p class="test3">Business Data</p>
          <p class="test4">Intelligent Material Collection System</p>
        </div>
        <div class="abs iconBackground1"></div>
        <div class="abs iconBackground2"></div>
        <div class="abs iconBackground3"></div>
        <div class="abs iconBackground4"></div>
        <div class="abs iconBackground5"></div>
      </div>
      <div class="login" v-if="!isFast" >
        <div class="loginImg">
          <div class="loginIcon"></div>
          <p>欢迎登录</p>
        </div>
        <div class="loginBox">
          <div class="inputBox">
            <a-input placeholder="请输入企业域" size="large" v-model="userLogin.tenant" v-on:keyup.enter="loginByUserName"></a-input>
          </div>
          <div class="inputBox">
            <a-input placeholder="请输入用户名" size="large" v-model="userLogin.userName" v-on:keyup.enter="loginByUserName"></a-input>
          </div>
          <div class="inputBox">
            <a-input placeholder="请输入密码" size="large" :type="types" v-model="userLogin.password" v-on:keyup.enter="loginByUserName" style="width:360px;"></a-input>
            <a-icon @click="show" class="yanIcon" :class="flags ? 'open':'close'"></a-icon>
          </div>
<!--          <div class="inputBox">-->
<!--            <a-input placeholder="请输入验证码" size="large" type="password" v-model="userLogin.captcha" v-on:keyup.enter="loginByUserName" style="width:310px;"></a-input>-->
<!--            <div class="captchaImg">-->
<!--              <img :src="captchaUrl" style="cursor: pointer;height:32px;width:100%;display:block;" @click="refresh"/>-->
<!--            </div>-->
<!--          </div>-->
          <div style="margin-top: 15px;float:left;">
            <a-checkbox @change="rememberMe" v-model="isRememberMe">记住账号</a-checkbox>
          </div>
          <div style="margin-top: 15px; text-align: center;float:right;" v-if="errorMsg">
              <span style="text-align: center; color: #e75353; font-size: 14px;">
                {{ errorMsg }}
              </span>
          </div>
          <div style="margin-top: 15px;">
            <a-button style="width: 100%;height: 58px;background: #233264;margin-top:20px;"
                      type="primary"
                      size="large"
                      @click="loginByUserName">
              登录
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as constant from '../public/constant';

export default {
  data() {
    return {
      types: 'password',
      flags: false,
    };
  },
  computed: {
    ...mapState({
      errorMsg: state => state.login.data.errorMsg,
      error: state => state.login.data.error,
      loading: state => state.login.data.loading,
      userLogin: state => state.login.userLogin,
      phoneLogin: state => state.login.phoneLogin,
      appInfo: state => state.login.data.appInfo,
      isTenant: state => state.login.data.isTenant,
      isFast: state => state.login.data.isFast,
      isCaptcha: state => state.login.data.isCaptcha,
      captchaUrl: state => state.login.data.captchaUrl,
      timer: state => state.login.data.timer,
    }),
    isRememberMe: {
      get() {
        return this.$store.state.login.data.isRememberMe;
      },

      set(val) {
        this.$store.commit('rememberMe', val);
      }
    }
  },

  created() {
    this.$store.dispatch('init');
    this.$store.dispatch('copyRememberMeUser');
    this.$store.dispatch('copyRememberMePhone');
    this.$store.dispatch('getAppInfo').then(() => {
      this.$store.dispatch('triggerCaptcha').then((res) => {
        if (res) {
          this.$store.dispatch('getCaptcha');
        }
      });
    });
  },

  methods: {
    /* 显示隐藏密码 */
    show() {
      this.types = this.types === 'password' ? 'text' : 'password';
      this.flags = !this.flags;
    },

    /* 记住账号 */
    rememberMe(e) {
      this.$store.dispatch('rememberMe', e.target.checked);
    },

    send() {
      this.$store.dispatch('send', this).then(() => {
        this.$store.dispatch('triggerCaptcha').then((res) => {
          if (res) {
            this.$store.dispatch('getCaptcha');
          }
        });
      });
    },

    loginByUserName() {
      this.$store.dispatch('loginByUserName', this).then((type) => {
        if (type === constant.CAPTCHA) {
          this.$store.dispatch('triggerCaptcha').then((res) => {
            if (res) {
              this.$store.dispatch('getCaptcha');
            }
          });
        }

        if (type === constant.MFA) {
          this.$router.push('/mfa');
        }
      });
    },

    loginByPhone() {
      this.$store.dispatch('loginByPhone', this).then((type) => {
        if (type === constant.CAPTCHA) {
          this.$store.dispatch('triggerCaptcha').then((res) => {
            if (res) {
              this.$store.dispatch('getCaptcha');
            }
          });
        }

        if (type === constant.MFA) {
          this.$router.push('/mfa');
        }
      });
    },

    loginByTicket(item) {
      this.$store.dispatch('loginByTicket', item);
    },

    changeLoginMode() {
      this.$store.dispatch('changeLoginMode');
    },

    /* 切换验证码 */
    refresh() {
      this.$store.dispatch('triggerCaptcha').then((res) => {
        if (res) {
          this.$store.dispatch('getCaptcha');
        }
      });
    },

  }
};
</script>

<style lang="less" scoped>
.login-content {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(192deg, #F4F3FC 14%, #E7E8EB 31%, #BFD2FC 99%, #00FFFF 100%);
  .navContent{
    max-width: 1920px;
    height: 100%;
    margin: 0 auto;
    position: relative;
  }
}

.login {
  background-color: #fff;
  width: 618px;
  display: flex;
  height: 100%;
  float: right;
  position: absolute;
  right: 0;
  background-image: url('../assets/qietu02.png'),url('../assets/qietu03.png');
  background-repeat: no-repeat,no-repeat;
  background-position:top , bottom;
  background-size: 100% 219px , 100% 169px;
  padding: 293px 90px 0 90px;
  box-sizing: border-box;
  .loginImg{
    position: absolute;
    top: 140px;
    left: 83px;
    .loginIcon{
      width: 80px;
      height: 80px;
      background: url('../assets/qietu01.png') no-repeat;
      background-size: 100% 100%;
    }
    p{
      margin-top: 15px;
      font-size: 28px;
      color: #3B4960;
      letter-spacing: 0;
      font-weight: 700;
      margin-left: 8px;
    }
  }
  .loginBox{
    position: relative;
    .inputBox{
      width: 437px;
      height: 58px;
      line-height: 58px;
      background: #FFFFFF;
      border: 1px solid #C9CDD4;
      border-radius: 8px;
      font-size: 16px;
      color: #1D2129;
      margin-bottom: 16px;
      padding: 0 19px;
      box-sizing: border-box;
      position: relative;
      .yanIcon{
        position: absolute;
        width: 20px;
        height: 20px;
        top: 20px;
        right: 18px;
        cursor: pointer;
      }
      .yanIcon.open{
        background: url('../assets/qietu09.png') no-repeat;
        background-size: 100% 100%;
      }
      .yanIcon.close{
        background: url('../assets/qietu10.png') no-repeat;
        background-size: 100% 100%;
      }
      .ant-input{
        border:0;
      }
      input{
        padding: 6px 0;
      }
      .captchaImg{
        width: 86px;
        height: 36px;
        float: right;
        margin-top: 10px;
      }
    }
  }
}
.exhibitionMap{
  max-width: 1300px;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  .abs{
    position: absolute;
  }
  .test{
    left:104px;
    top:53px;
    .test1{
      font-size: 32px;
      line-height: 32px;
      color: #6C768A;
      letter-spacing: 0;
      font-weight: 500;
      margin-bottom: 8px;
    }
    .test2{
      font-size: 60px;
      line-height: 60px;
      color: #233264;
      letter-spacing: 9.1px;
      font-weight: 900;
      margin-bottom: 60px;
    }
    .test3{
      font-size: 18px;
      line-height: 20px;
      color: #6C768A;
      letter-spacing: 0;
      font-weight: 400;
      margin-bottom: 0;
    }
    .test4{
      font-size: 18px;
      line-height: 20px;
      color: #6C768A;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
  .iconBackground1{
    top: 205px;
    left: 296px;
    // width: 774px;
    // height: 738px;
    width: 735px;
    height: 700px;
    background: url('../assets/qietu04.png') no-repeat;
    background-size: 100% 100%;
  }
  .iconBackground2{
    top: 510px;
    left: 63px;
    width: 133px;
    height: 133px;
    background: url('../assets/qietu06.png') no-repeat;
    background-size: 100% 100%;
    animation: floatAnimation 5.5s infinite;
  }
  .iconBackground3{
    top: 66px;
    left: 933px;
    width: 101px;
    height: 120px;
    background: url('../assets/qietu07.png') no-repeat;
    background-size: 100% 100%;
    animation: floatAnimation 4.5s infinite;
  }
  .iconBackground4{
    top: 125px;
    left: 1050px;
    width: 127px;
    height: 154px;
    background: url('../assets/qietu08.png') no-repeat;
    background-size: 100% 100%;
    animation: floatAnimation 8s infinite;
  }
  .iconBackground5{
    top: 820px;
    left: 1060px;
    width: 104px;
    height: 121px;
    background: url('../assets/qietu07.png') no-repeat;
    background-size: 100% 100%;
    animation: floatAnimation 5s infinite;
  }
  @keyframes floatAnimation {
    0%{
      transform: translateY(0);
    }
    50%{
      transform: translateY(-25px);
    }
    100%{
      transform: translateY(0);
    }
  }
}
.title{
  margin: 0;
  display: flex; justify-content: center; font-size: 18px; margin-top: 40px; color: #b3adad; margin-bottom: 0px;
  img{
    vertical-align: middle;
    margin-right: 8px;
  }
  dl{
    margin:0;
    dt{
      margin:0;
      font-family: SourceHanSansCN-Medium;
      font-size: 20px;
      color: #273250;
      letter-spacing: 0;
      font-weight: 500;
      margin: 0;
    }
    dd{
      margin:0;
      font-family: SourceHanSansCN-Medium;
      font-size: 12px;
      color: #273250;
      letter-spacing: 0;
      font-weight: 500;
      margin: 0;
    }
  }
  .pt{
    border-right: 1px solid #C9CDD4;
    padding-right: 20px;
    margin-right: 20px;
  }
}
.login-fast {
  background-color: #fff;
  border-radius: 10px;
  min-width: 420px;
  height: 470px;
  box-shadow: 0 14px 28px #cdc5c5, 0 10px 10px #cdc5c5;
  margin-top: 100px;
}
.login-ss {
  box-shadow: 0 0 0;
}
.more-login {
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: end;
  cursor: pointer;
}

.login-fast-item {
  justify-content: center;
  display: flex;
  box-shadow: 0px 14px 28px #9d9d9d;
  margin: 20px 20px;
  padding: 30px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
::v-deep .ant-input:placeholder-shown,::v-deep input[type='text'],::v-deep .ant-input-affix-wrapper .ant-input{
  border: none !important;
}
::v-deep .ant-input:focus{
 box-shadow: none;
}
.box{
 margin-top: 10px;
 border: 1px solid #C9CDD4;
 padding-top: 10px;
 border-radius: 3px;
 font-size: 12px;
 .ant-input ant-input-lg{
  font-size: 14px;
 }
}
::v-deep .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),::v-deep .ant-input:hover{
  border:none;
}
::v-deep :focus-visible,.ant-input{
    outline: none;
}
</style>
