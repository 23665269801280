<template>
  <div class="login-content">
    <div class="login" v-if="!isFast" :style="isCaptcha?'height:650px':'height:550px'">
      <div style="width:502px;">
        <div class="title">
        <img width="40px" height="40px" src="../assets/qietu5.png" >
        <dl class="pt">
          <dt> {{ appInfo.name }}登录</dt>
          <dd>System Name</dd>
        </dl>
        <dl>
          <dt>欢迎回来</dt>
          <dd>Welcome Back</dd>
        </dl>
      </div>
      <div style="margin:0 30px 30px 30px;">
        <a-spin :spinning="loading">
          <a-tabs @change="changeTab">
            <a-tab-pane key="1" tab="密码">
              <div class="box" v-if="isTenant">
                <p style="margin:0;padding-left: 10px;">企业域</p>
                <a-input placeholder="请输入企业域"
                         size="large"
                         v-model="userLogin.tenant"
                         v-on:keyup.enter="loginByUserName">
                </a-input>
              </div>
              <div class="box">
                <p style="margin:0;padding-left: 10px;">用户名</p>
                <a-input placeholder="请输入用户名"
                         size="large"
                         v-model="userLogin.userName"
                         v-on:keyup.enter="loginByUserName">
                </a-input>
              </div>
              <div class="box">
                <p style="margin:0;padding-left: 10px;">密码</p>
                <a-input-password placeholder="请输入密码"
                                  size="large"
                                  type="password"
                                  v-model="userLogin.password"
                                  v-on:keyup.enter="loginByUserName">
                </a-input-password>
              </div>

              <div class="box" v-if="isCaptcha">
                <p style="margin:0;padding-left: 10px;">验证码</p>
                <div style="display: flex;">
                  <a-input v-model="userLogin.captcha" placeholder="请输入验证码" size="large" style="width: 200px; margin-right: 20px;" v-on:keyup.enter="loginByUserName">
                  </a-input>
                  <div>
                    <img :src="captchaUrl" style="cursor: pointer;height:32px;" @click="refresh"/>
                  </div>
                </div>
              </div>
              <div style="margin-top: 15px;">
                <a-button style="width: 50%;"
                          type="primary"
                          size="large"
                          @click="loginByUserName">
                  登录
                </a-button>
              </div>
              <div style="margin-top: 15px;float:left;">
                <a-checkbox @change="rememberMe" v-model="isRememberMe">记住我</a-checkbox>
              </div>
              <div style="margin-top: 15px; text-align: center;float:right;" v-if="errorMsg">
                  <span style="text-align: center; color: #e75353; font-size: 14px;">
                    {{ errorMsg }}
                  </span>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="短信验证码">
              <div  class="box" v-if="isTenant">
                <p style="margin:0;padding-left: 10px;">企业域</p>
                <a-input placeholder="请输入企业域"
                         size="large"
                         v-model="phoneLogin.tenant" v-on:keyup.enter="loginByPhone">
                </a-input>
              </div>
              <div  class="box">
                <p style="margin:0;padding-left: 10px;">手机号</p>
                <a-input placeholder="请输入手机号"
                         size="large"
                         v-model="phoneLogin.phone" v-on:keyup.enter="loginByPhone">
                </a-input>
              </div>
              <div class="box" style="overflow:hidden;">
                <p style="margin:0;padding-left: 10px;">验证码</p>
                <a-input style="float:left;width:50%;" placeholder="请输入验证码"
                         size="large"
                         v-model="phoneLogin.code" v-on:keyup.enter="loginByPhone">
                </a-input>
                <a-button type="link" style="float:right;margin-top: 5px; width: 120px;" :disabled="timer > 0 && timer !== 60" @click="send">
                  {{ (timer === 0 || timer === 60) ? '获取验证码' : '重新发送(' + timer + ')' }}
                </a-button>
              </div>
              <div class="box" v-if="isCaptcha">
                <p style="margin:0;padding-left: 10px;">验证码</p>
                <div style="display: flex;">
                  <a-input v-model="phoneLogin.captcha" placeholder="请输入验证码" size="large" style="width: 200px; margin-right: 20px;" v-on:keyup.enter="loginByPhone">
                  </a-input>
                  <div>
                    <img :src="captchaUrl" style="cursor: pointer;height:32px;" @click="refresh"/>
                  </div>
                </div>
              </div>
              <div style="margin-top: 15px;">
                <a-button style="width: 50%;"
                          type="primary"
                          size="large"
                          @click="loginByPhone">
                  登录
                </a-button>
              </div>
              <div style="margin-top: 15px;width:100px;float:left;">
                <a-checkbox @change="rememberMe" v-model="isRememberMe">记住我</a-checkbox>
              </div>
              <div style="margin-top: 15px; text-align: center;float:right;" v-if="errorMsg">
                  <span style="text-align: center; color: #e75353; font-size: 14px; ">
                    {{ errorMsg }}
                  </span>
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-spin>
      </div>
      </div>
      <div class="loginIcon">
        <img width="100%" src="../assets/qietu3.png" >
      </div>
    </div>

    <div class="login-fast" v-bind:class="{ 'login-ss': isCustom }" v-if="isFast">
      <div>
        <div class="more-login" @click="changeLoginMode">
          <div style="height: 32px; line-height: 32px; margin-right: 15px; margin-left: 10px;">更多登录</div>
          <div>
            <svg t="1668569724281" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11675" width="32" height="32"><path d="M991.744 763.904l-102.4-50.176c-4.608-2.048-9.728-2.048-14.336 0l-338.944 166.4c-14.848 7.168-32.768 7.168-47.616 0l-338.944-166.4c-4.608-2.048-9.728-2.048-14.336 0l-102.4 50.176c-12.8 6.144-12.8 24.576 0 30.72l455.68 223.744c14.848 7.168 32.768 7.168 47.616 0l455.68-223.744c12.8-6.144 12.8-24.064 0-30.72z" p-id="11676" fill="#13227a"></path><path d="M991.744 499.712l-102.4-50.176c-4.608-2.048-9.728-2.048-14.336 0l-338.944 166.4c-14.848 7.168-32.768 7.168-47.616 0L148.992 449.536c-4.608-2.048-9.728-2.048-14.336 0l-102.4 50.176c-12.8 6.144-12.8 24.576 0 30.72l455.68 223.744c14.848 7.168 32.768 7.168 47.616 0l455.68-223.744c13.312-6.144 13.312-24.576 0.512-30.72z" p-id="11677" fill="#13227a"></path><path d="M488.448 483.328l-455.68-223.744c-12.8-6.144-12.8-24.576 0-30.72L488.448 5.12c14.848-7.168 32.768-7.168 47.616 0l455.68 223.744c12.8 6.144 12.8 24.576 0 30.72l-455.68 223.744c-15.36 7.168-32.768 7.168-47.616 0z" p-id="11678" fill="#13227a"></path></svg>
          </div>
        </div>
        <div style="display: flex; justify-content: center; width: 100%; font-size: 18px; margin-top: 20px; color: #b3adad; margin-bottom: 20px;">{{ appInfo.name }}登录</div>
      </div>
      <div style="margin: 20px 30px;">
        <a-spin :spinning="loading">
          <a-tabs>
            <a-tab-pane key="3" tab="快速登录">
              <div>点击下方已经登录的应用快速登录</div>
              <div style="justify-content: center; display: flex; flex-wrap: nowrap; overflow: auto;">
                <div v-for="(item, index) of appInfo.ticketConfirmResponses" :key="index" class="login-fast-item" @click="loginByTicket(item)">
                  <div>
                    <div>
                      <svg t="1668564709635" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2703" width="64" height="64"><path d="M483.555556 10.723556a85.333333 85.333333 0 0 1 82.574222 0l381.952 211.057777a85.333333 85.333333 0 0 1 44.088889 74.695111v419.015112a85.333333 85.333333 0 0 1-44.088889 74.666666l-381.952 211.114667a85.333333 85.333333 0 0 1-82.545778 0l-381.952-211.057778a85.333333 85.333333 0 0 1-44.088889-74.723555v-418.986667A85.333333 85.333333 0 0 1 101.632 221.866667L483.555556 10.695111v0.028445zM222.151111 338.688a42.666667 42.666667 0 0 0 16.668445 58.026667l243.370666 134.456889v268.174222a42.666667 42.666667 0 0 0 85.333334 0v-268.174222l243.370666-134.542223a42.666667 42.666667 0 0 0-41.244444-74.638222l-244.792889 135.253333-244.736-135.253333a42.666667 42.666667 0 0 0-57.969778 16.725333z" p-id="2704" fill="#316e8f"></path></svg>
                    </div>
                    <div style="margin-top: 20px;">
                      {{ item.appName }}({{ item.tenant }})
                    </div>
                  </div>
                </div>
              </div>
              <div style="margin-top: 25px; text-align: center;" v-if="errorMsg">
                    <span style="text-align: center; color: #e75353; font-size: 14px; ">
                      {{ errorMsg }}
                    </span>
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-spin>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as constant from '../public/constant';

export default {
  computed: {
    ...mapState({
      errorMsg: state => state.login.data.errorMsg,
      error: state => state.login.data.error,
      loading: state => state.login.data.loading,
      userLogin: state => state.login.userLogin,
      phoneLogin: state => state.login.phoneLogin,
      appInfo: state => state.login.data.appInfo,
      isTenant: state => state.login.data.isTenant,
      isFast: state => state.login.data.isFast,
      isCaptcha: state => state.login.data.isCaptcha,
      captchaUrl: state => state.login.data.captchaUrl,
      timer: state => state.login.data.timer,
    }),
    isRememberMe: {
      get() {
        return this.$store.state.login.data.isRememberMe;
      },

      set(val) {
        this.$store.commit('rememberMe', val);
      }
    }
  },

  created() {
    this.$store.dispatch('init');
    this.$store.dispatch('copyRememberMeUser');
    this.$store.dispatch('copyRememberMePhone');
    this.$store.dispatch('getAppInfo').then(() => {
      this.$store.dispatch('triggerCaptcha').then((res) => {
        if (res) {
          this.$store.dispatch('getCaptcha');
        }
      });
    });
  },

  methods: {
    rememberMe(e) {
      this.$store.dispatch('rememberMe', e.target.checked);
    },

    send() {
      this.$store.dispatch('send', this).then(() => {
        this.$store.dispatch('triggerCaptcha').then((res) => {
          if (res) {
            this.$store.dispatch('getCaptcha');
          }
        });
      });
    },

    loginByUserName() {
      this.$store.dispatch('loginByUserName', this).then((type) => {
        if (type === constant.CAPTCHA) {
          this.$store.dispatch('triggerCaptcha').then((res) => {
            if (res) {
              this.$store.dispatch('getCaptcha');
            }
          });
        }

        if (type === constant.MFA) {
          this.$router.push('/mfa');
        }
      });
    },

    loginByPhone() {
      this.$store.dispatch('loginByPhone', this).then((type) => {
        if (type === constant.CAPTCHA) {
          this.$store.dispatch('triggerCaptcha').then((res) => {
            if (res) {
              this.$store.dispatch('getCaptcha');
            }
          });
        }

        if (type === constant.MFA) {
          this.$router.push('/mfa');
        }
      });
    },

    loginByTicket(item) {
      this.$store.dispatch('loginByTicket', item);
    },

    changeLoginMode() {
      this.$store.dispatch('changeLoginMode');
    },

    refresh() {
      this.$store.dispatch('triggerCaptcha').then((res) => {
        if (res) {
          this.$store.dispatch('getCaptcha');
        }
      });
    },

  }
};
</script>

<style lang="less" scoped>
.login-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-image: url('../assets/bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.login {
  background-color: #fff;
  border-radius: 10px;
  // width: 420px;
  display: flex;
  height: 680px;
  margin-top: 5%;
  .loginIcon{
    width: 450px;
    padding-right: 36px;
    img{
      margin-top: 110px;
    }
  }
}
.title{
  margin: 0;
  display: flex; justify-content: center; font-size: 18px; margin-top: 40px; color: #b3adad; margin-bottom: 0px;
  img{
    vertical-align: middle;
    margin-right: 8px;
  }
  dl{
    margin:0;
    dt{
      margin:0;
      font-family: SourceHanSansCN-Medium;
      font-size: 20px;
      color: #273250;
      letter-spacing: 0;
      font-weight: 500;
      margin: 0;
    }
    dd{
      margin:0;
      font-family: SourceHanSansCN-Medium;
      font-size: 12px;
      color: #273250;
      letter-spacing: 0;
      font-weight: 500;
      margin: 0;
    }
  }
  .pt{
    border-right: 1px solid #C9CDD4;
    padding-right: 20px;
    margin-right: 20px;
  }
}
.login-fast {
  background-color: #fff;
  border-radius: 10px;
  min-width: 420px;
  height: 470px;
  box-shadow: 0 14px 28px #cdc5c5, 0 10px 10px #cdc5c5;
  margin-top: 100px;
}

.login-ss {
  box-shadow: 0 0 0;
}

.more-login {
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: end;
  cursor: pointer;
}

.login-fast-item {
  justify-content: center;
  display: flex;
  box-shadow: 0px 14px 28px #9d9d9d;
  margin: 20px 20px;
  padding: 30px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
::v-deep .ant-input:placeholder-shown,::v-deep input[type='text'],::v-deep .ant-input-affix-wrapper .ant-input{
  border: none !important;
}
::v-deep .ant-input:focus{
 box-shadow: none;
}
.box{
 margin-top: 10px;
 border: 1px solid #C9CDD4;
 padding-top: 10px;
 border-radius: 3px;
 font-size: 12px;
 .ant-input ant-input-lg{
  font-size: 14px;
 }
}
::v-deep .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),::v-deep .ant-input:hover{
  border:none;
}
::v-deep :focus-visible,.ant-input{
    outline: none;
}
</style>
