<template>
  <div class="login-content">
    <div class="login" v-if="!isFast">
      <div style="display: flex; justify-content: center; font-size: 18px; margin-top: 40px; color: #b3adad; margin-bottom: 20px;">
        {{ appInfo.name }}登录
      </div>
      <!-- 手机端(并且必须是生产环境) -->
      <div v-if="isMobile() && isPro()" style="margin: 30px;">
        <a-spin :spinning="loading">
          <div style="margin-top: 15px;" v-if="isTenant">
            <a-input placeholder="请输入企业域"
              size="large"
              v-model="phoneLogin.tenant" v-on:keyup.enter="loginByPhone">
              <a-icon slot="prefix" type="home"></a-icon>
            </a-input>
          </div>
          <div style="margin-top: 15px;">
            <a-input placeholder="请输入手机号"
              size="large"
              v-model="phoneLogin.phone" v-on:keyup.enter="loginByPhone">
              <a-icon slot="prefix" type="mobile"></a-icon>
            </a-input>
          </div>
          <div style="margin-top: 15px; display: flex;">
            <a-input placeholder="请输入验证码"
              size="large"
              v-model="phoneLogin.code" v-on:keyup.enter="loginByPhone">
              <a-icon slot="prefix" type="message"></a-icon>
            </a-input>
            <a-button type="link" style="margin-top: 5px; width: 120px;" :disabled="timer > 0 && timer !== 60" @click="send">
              {{ (timer === 0 || timer === 60) ? '获取验证码' : '重新发送(' + timer + ')' }}
            </a-button>
          </div>
          <div style="display: flex; margin-top: 15px;" v-if="isCaptcha">
            <a-input v-model="phoneLogin.captcha" placeholder="请输入验证码" size="large" style="width: 200px; margin-right: 20px;" v-on:keyup.enter="loginByPhone">
              <a-icon slot="prefix" type="picture"></a-icon>
            </a-input>
            <div>
              <img :src="captchaUrl" style="cursor: pointer;" @click="refresh"/>
            </div>
          </div>
          <div style="margin-top: 15px; display: flex; justify-content: right;">
            <a-checkbox @change="rememberMe" v-model="isRememberMe">记住我</a-checkbox>
          </div>
          <div style="margin-top: 15px;">
            <a-button style="width: 100%;"
              type="primary"
              size="large"
              @click="loginByPhone">
              登录
            </a-button>
          </div>
          <div style="margin-top: 25px; text-align: center;" v-if="errorMsg">
            <span style="text-align: center; color: #e75353; font-size: 14px; ">
              {{ errorMsg }}
            </span>
          </div>
        </a-spin>
      </div>
      <!-- pc端 -->
      <div v-else style="margin: 30px;">
        <a-spin :spinning="loading">
          <a-tabs>
            <a-tab-pane key="1" tab="密码">
              <div style="margin-top: 15px;" v-if="isTenant">
                <a-input placeholder="请输入企业域"
                         size="large"
                         v-model="userLogin.tenant"
                         v-on:keyup.enter="loginByUserName">
                  <a-icon slot="prefix" type="home"></a-icon>
                </a-input>
              </div>
              <div style="margin-top: 15px;">
                <a-input placeholder="请输入用户名"
                         size="large"
                         v-model="userLogin.userName"
                         v-on:keyup.enter="loginByUserName">
                  <a-icon slot="prefix" type="user"></a-icon>
                </a-input>
              </div>
              <div style="margin-top: 15px;">
                <a-input-password placeholder="请输入密码"
                                  size="large"
                                  type="password"
                                  v-model="userLogin.password"
                                  v-on:keyup.enter="loginByUserName">
                  <a-icon slot="prefix" type="lock"></a-icon>
                </a-input-password>
              </div>

              <div style="display: flex; margin-top: 15px;" v-if="isCaptcha">
                <a-input v-model="userLogin.captcha" placeholder="请输入验证码" size="large" style="width: 200px; margin-right: 20px;" v-on:keyup.enter="loginByUserName">
                  <a-icon slot="prefix" type="picture"></a-icon>
                </a-input>
                <div>
                  <img :src="captchaUrl" style="cursor: pointer;" @click="refresh"/>
                </div>
              </div>

              <div style="margin-top: 15px; display: flex; justify-content: right;">
                <a-checkbox @change="rememberMe" v-model="isRememberMe">记住我</a-checkbox>
              </div>
              <div style="margin-top: 15px;">
                <a-button style="width: 100%;"
                          type="primary"
                          size="large"
                          @click="loginByUserName">
                  登录
                </a-button>
              </div>
              <div style="margin-top: 25px; text-align: center;" v-if="errorMsg">
                  <span style="text-align: center; color: #e75353; font-size: 14px;">
                    {{ errorMsg }}
                  </span>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="短信验证码">
            <!-- <a-tab-pane key="2" tab="短信验证码" v-if="appInfo && appInfo.messageCode"> -->
              <div style="margin-top: 15px;" v-if="isTenant">
                <a-input placeholder="请输入企业域"
                         size="large"
                         v-model="phoneLogin.tenant" v-on:keyup.enter="loginByPhone">
                  <a-icon slot="prefix" type="home"></a-icon>
                </a-input>
              </div>
              <div style="margin-top: 15px;">
                <a-input placeholder="请输入手机号"
                         size="large"
                         v-model="phoneLogin.phone" v-on:keyup.enter="loginByPhone">
                  <a-icon slot="prefix" type="mobile"></a-icon>
                </a-input>
              </div>
              <div style="margin-top: 15px; display: flex;">
                <a-input placeholder="请输入验证码"
                         size="large"
                         v-model="phoneLogin.code" v-on:keyup.enter="loginByPhone">
                  <a-icon slot="prefix" type="message"></a-icon>
                </a-input>
                <a-button type="link" style="margin-top: 5px; width: 120px;" :disabled="timer > 0 && timer !== 60" @click="send">
                  {{ (timer === 0 || timer === 60) ? '获取验证码' : '重新发送(' + timer + ')' }}
                </a-button>
              </div>
              <div style="display: flex; margin-top: 15px;" v-if="isCaptcha">
                <a-input v-model="phoneLogin.captcha" placeholder="请输入验证码" size="large" style="width: 200px; margin-right: 20px;" v-on:keyup.enter="loginByPhone">
                  <a-icon slot="prefix" type="picture"></a-icon>
                </a-input>
                <div>
                  <img :src="captchaUrl" style="cursor: pointer;" @click="refresh"/>
                </div>
              </div>
              <div style="margin-top: 15px; display: flex; justify-content: right;">
                <a-checkbox @change="rememberMe" v-model="isRememberMe">记住我</a-checkbox>
              </div>
              <div style="margin-top: 15px;">
                <a-button style="width: 100%;"
                          type="primary"
                          size="large"
                          @click="loginByPhone">
                  登录
                </a-button>
              </div>
              <div style="margin-top: 25px; text-align: center;" v-if="errorMsg">
                <span style="text-align: center; color: #e75353; font-size: 14px; ">
                  {{ errorMsg }}
                </span>
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-spin>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import * as constant from '../public/constant';

export default {
  computed: {
    ...mapState({
      errorMsg: state => state.login.data.errorMsg,
      error: state => state.login.data.error,
      loading: state => state.login.data.loading,
      userLogin: state => state.login.userLogin,
      phoneLogin: state => state.login.phoneLogin,
      appInfo: state => state.login.data.appInfo,
      isTenant: state => state.login.data.isTenant,
      isFast: state => state.login.data.isFast,
      isCaptcha: state => state.login.data.isCaptcha,
      captchaUrl: state => state.login.data.captchaUrl,
      timer: state => state.login.data.timer,
    }),
    isRememberMe: {
      get() {
        return this.$store.state.login.data.isRememberMe;
      },

      set(val) {
        this.$store.commit('rememberMe', val);
      }
    }
  },

  created() {
    this.$store.dispatch('init');
    this.$store.dispatch('copyRememberMeUser');
    this.$store.dispatch('copyRememberMePhone');
    this.$store.dispatch('getAppInfo').then(() => {
      this.$store.dispatch('triggerCaptcha').then((res) => {
        if (res) {
          this.$store.dispatch('getCaptcha');
        }
      });
    });
  },

  methods: {
    rememberMe(e) {
      this.$store.dispatch('rememberMe', e.target.checked);
    },

    send() {
      this.$store.dispatch('send', this).then(() => {
        this.$store.dispatch('triggerCaptcha').then((res) => {
          if (res) {
            this.$store.dispatch('getCaptcha');
          }
        });
      });
    },

    loginByUserName() {
      this.$store.dispatch('loginByUserName', this).then((type) => {
        if (type === constant.CAPTCHA) {
          this.$store.dispatch('triggerCaptcha').then((res) => {
            if (res) {
              this.$store.dispatch('getCaptcha');
            }
          });
        }

        if (type === constant.MFA) {
          this.$router.push('/mfa');
        }
      });
    },

    loginByPhone() {
      this.$store.dispatch('loginByPhone', this).then((type) => {
        if (type === constant.CAPTCHA) {
          this.$store.dispatch('triggerCaptcha').then((res) => {
            if (res) {
              this.$store.dispatch('getCaptcha');
            }
          });
        }

        if (type === constant.MFA) {
          this.$router.push('/mfa');
        }
      });
    },

    loginByTicket(item) {
      this.$store.dispatch('loginByTicket', item);
    },

    changeLoginMode() {
      this.$store.dispatch('changeLoginMode');
    },

    refresh() {
      this.$store.dispatch('triggerCaptcha').then((res) => {
        if (res) {
          this.$store.dispatch('getCaptcha');
        }
      });
    },

    // 判断设备true是移动设备
    isMobile() {
      const ua = navigator.userAgent;
      return /mobile/i.test(ua);
    },

    // 判断生产环境
    isPro() {
      if (window.location.hostname.indexOf('sso.kaitaiming.com') > -1) {
        return true;
      }
      return false;
    },
  }
};
</script>

<style lang="less" scoped>
.login-content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.login {
  background-color: #fff;
  border-radius: 10px;
  width: 420px;
  min-height: 470px;
  box-shadow: 0 14px 28px #cdc5c5, 0 10px 10px #cdc5c5;
  margin-top: 80px;
}

.login-fast {
  background-color: #fff;
  border-radius: 10px;
  min-width: 420px;
  height: 470px;
  box-shadow: 0 14px 28px #cdc5c5, 0 10px 10px #cdc5c5;
  margin-top: 100px;
}

.login-ss {
  box-shadow: 0 0 0;
}

.more-login {
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: end;
  cursor: pointer;
}

.login-fast-item {
  justify-content: center;
  display: flex;
  box-shadow: 0px 14px 28px #9d9d9d;
  margin: 20px 20px;
  padding: 30px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
</style>
