<template>
  <div class="mfa-content">
    <div class="mfa-content-warpper">
      <div style="display: flex; justify-content: center; font-size: 18px; margin-top: 40px; color: #b3adad; margin-bottom: 20px;">
        登录验证
      </div>
      <div style="margin: 30px;">
        <a-spin :spinning="loading">
          <div style="margin-top: 25px; display: flex; color: #666768;" v-if="loginResult.mfaMessageCode">
            请通过您绑定的手机号{{ loginResult.phone  }}获取验证码，验证登录
          </div>
          <div style="margin-top: 25px; display: flex;" v-if="loginResult.mfaMessageCode">
            <a-input placeholder="请输入手机收到的验证码"
                     size="large"
                     style="width: 300px;"
                     v-model="messageCode" v-on:keyup.enter="submit">
              <a-icon slot="prefix" type="message"></a-icon>
            </a-input>
            <a-button type="link" style="margin-top: 5px; width: 120px;" :disabled="timer > 0 && timer !== 60" @click="send">
              {{ (timer === 0 || timer === 60) ? '获取验证码' : '重新发送(' + timer + ')' }}
            </a-button>
          </div>
<!--          <div style="margin-top: 25px; display: flex; color: #666768;" v-if="loginResult.mfaEmailCode">-->
<!--            请通过您绑定的邮箱{{ loginResult.email  }}获取验证码，验证登录-->
<!--          </div>-->
<!--          <div style="margin-top: 25px; display: flex;" v-if="loginResult.mfaEmailCode">-->
<!--            <a-input placeholder="请输入邮箱收到的验证码"-->
<!--                     size="large"-->
<!--                     style="width: 300px;"-->
<!--                     v-model="messageCode" v-on:keyup.enter="submit">-->
<!--              <a-icon slot="prefix" type="message"></a-icon>-->
<!--            </a-input>-->
<!--            <a-button type="link" style="margin-top: 5px; width: 120px;" :disabled="timer > 0 && timer !== 60" @click="send">-->
<!--              {{ (timer === 0 || timer === 60) ? '获取验证码' : '重新发送(' + timer + ')' }}-->
<!--            </a-button>-->
<!--          </div>-->
          <div style="margin-top: 35px; margin-bottom: 50px;">
            <a-button style="width: 100%;"
                      type="primary"
                      size="large"
                      @click="submit">
              提交验证
            </a-button>
          </div>
        </a-spin>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      messageCode: '',
      timer: 60
    };
  },

  computed: mapState({
    userLogin: state => state.login.userLogin,
    phoneLogin: state => state.login.phoneLogin,
    appInfo: state => state.login.data.appInfo,
    loginResult: state => state.login.data.loginResult,
  }),

  methods: {
    submit() {
      if (!this.messageCode) {
        this.$message.error('请输入验证码');
        return;
      }

      const data = {
        tenant: this.userLogin.tenant ? this.userLogin.tenant : this.phoneLogin.tenant,
        appKey: this.userLogin.appKey,
        sessionId: this.loginResult.sessionId,
        code: this.messageCode
      };

      this.loading = true;
      const url = this.$url.getBackendApiUrl('/authencation/oauth2/mfa/phone');
      this.$http.post(url, data, this.$url.default_headers).then((res) => {
        if (res.status === 200 && res.data.success) {
          window.location.href = decodeURIComponent(`${res.data.data.data}`);
        }

        if (res.status === 200 && res.data.success === false) {
          this.$message.error(res.data.errMessage);
        }
        this.loading = false;
      });
    },

    send() {
      const data = {
        tenant: this.userLogin.tenant ? this.userLogin.tenant : this.phoneLogin.tenant,
        appKey: this.userLogin.appKey,
        sessionId: this.loginResult.sessionId
      };

      this.loading = true;
      const url = this.$url.getBackendApiUrl('/authencation/oauth2/mfa/send/sms');
      this.$http.post(url, data, this.$url.default_headers).then((res) => {
        if (res.status === 200 && res.data.success) {
          this.timer = 59;
          this.timerDown();
          this.$message.success('验证码发送成功,请查收');
        }

        if (res.status === 200 && res.data.success === false) {
          this.$message.error(res.data.errMessage);
          if (res.data.errCode === 'MFA_SESSION_ID_NOT_FOUND') {
            this.$router.go(-1);
          }

          if (res.data.errCode === 'PHONE_SEND_MESSAGE_CODE_COUNT_LIMIT') {
            this.timer = 59;
            this.timerDown();
          }
        }
        this.loading = false;
      });
    },

    timerDown() {
      const clearTimer = setInterval(() => {
        this.timer -= 1;
        if (this.timer === 0) {
          clearInterval(clearTimer);
        }
      }, 1000);
    },
  }
};
</script>

<style lang="less" scoped>
.mfa-content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mfa-content-warpper {
  background-color: #fff;
  border-radius: 10px;
  width: 600px;
  min-height: 200px;
  box-shadow: 0 14px 28px #cdc5c5, 0 10px 10px #cdc5c5;
  margin-top: 100px;
}

.login-fast {
  background-color: #fff;
  border-radius: 10px;
  min-width: 420px;
  height: 470px;
  box-shadow: 0 14px 28px #cdc5c5, 0 10px 10px #cdc5c5;
  margin-top: 100px;
}

.login-ss {
  box-shadow: 0 0 0;
}

.more-login {
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: end;
  cursor: pointer;
}

.login-fast-item {
  justify-content: center;
  display: flex;
  box-shadow: 0px 14px 28px #9d9d9d;
  margin: 20px 20px;
  padding: 30px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}
</style>
